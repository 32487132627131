/*
 * Private Doina style
 * ************************************************************************************************ */



/*
 * Daily news - 2 columns keywords.
 * ************************************************************************************************ */

.taxonomy-content .form-checkboxes {
  clear: both; }

.taxonomy-content .form-item.form-type-checkbox.checkbox {
  float: left; }

.checkbox:first-child, .radio:first-child {
  margin-top: -5px; }



/*
 * Freelance content block
 * ************************************************************************************************ */

.main-page {
  font-size: 16px; }

.btns-contributor-page {
  margin-top: 20px; }

.btn-contrib-page {
  width: 100%; }

.btn-back-to-main {
  width: 100%; }

h1 {
  font-size: 24px; }

/*
 * disable buttons color
 * ********************************************/
button:disabled {
  background-color: #686868 !important; }

/*
 * daily-news source buttons align right
 * ********************************************/
/* .panel-body .form-wrapper.form-group
  text-align: right */
.form-inline.form-wrapper.form-group .btn {
  text-align: center; }

section.block-news {
  margin-right: 0px !important; }

// .field.field-label-inline.clearfix
//  width: 80%

// .field-item.even
//  width: 80%
//  float: right


.filters-panel label {
  text-transform: uppercase; }


/*
 * view form
 * ********************************************/
// .img-responsive
//   width: 100px

.text_count {
  color: #848484;
  text-transform: uppercase;
  font-size: 12px; }

.field-label.view_form {
  text-align: right;
  text-transform: uppercase; }

.field.field-label-above {
  padding: 5px 0;
  clear: both; }

.keywords_label {
  color: #b6b6b6; }

// .field > .field-label
//    display: none

.entity.entity-gin-pic-news-pic.gin-pic-news-pic-gin-pic-news-pic.clearfix h2 a {
  display: none; }

.field-item.even.title_heading {
  font-size: 26px;
  text-align: center; }

.field-name-field-inline-pictures-source.field-type-entityreference>.field-label {
  display: none; }


.date-form-element-content-multiline {
  border-style: none; }

.field-type-media.field-label-above {
  float: left; }

.entity.entity-gin-vid-news-vid.gin-vid-news-vid-gin-vid-news-vid.clearfix h2 a {
  display: none; }

.field-name-field-inline-video-source.field-type-entityreference > .field-label {
  display: none; }

.field-name-field-inline-text-source.field-type-entityreference > .field-label {
  display: none; }

// #news-form .dn_summary .text-summary-wrapper textarea
//  height: 120px

.main-container {
  padding-bottom: 20px; }

.form-item-field-auto-delete-date-und-0-value-date label {
  float: left !important;
  width: auto; }

.container-inline-date .form-item input {
  float: left !important;
  clear: none; }


#field-auto-delete-date-add-more-wrapper .date-form-element-content-multiline {
  padding: 0;
  border: 0; }


.has-error.radio label i {
  color: #686868; }

.form-item-field-new-media-type-und .radio input[type="radio"]:checked {
  color: #282828; }

.ctools-modal-dialog.modal-dialog {
  width: 450px; }

.ctools-modal-dialog.modal-dialog .modal-content {
  text-align: center; }

#edit-actions--2.form-actions.form-wrapper.form-group a {
  position: relative;
  background: #282828;
  padding: 6px 12px;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase; }

#edit-actions--2.form-actions.form-wrapper.form-group a:hover {
	background-color: #c8c8c8; }


#edit-actions--2.form-actions.form-wrapper {
  margin-top: 10px; }

#confirm-form.confirmation.ctools-use-modal-processed {
  font-size: 15px; }

.a:hover, .a:focus, .a:active {
  color: #fff;
  background: #c8c8c8; }

.views-exposed-widget.views-widget-filter-created .views-widget, .views-exposed-widget.views-widget-filter-created_1 .views-widget {
  width: 25% !important; }

.views-exposed-widget.views-widget-filter-created_1 label {
  padding-left: 15px; }

// #news-form-all-bundle .row,
// #news-order-form .row
//   margin-bottom: 15px


.form-item.form-item-field-auto-delete-date-und-0 .date-form-element-content-multiline {
  padding: 0; }

// video browse window

#mediaBrowse html.js {
  height: 350px !important; }

.video-source .field-name-field-vid-news-original .button {
  background: #282828;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 6 12px; }

.ui-dialog.ui-widget.ui-widget-content.ui-corner-all.ui-front.media-wrapper.ui-dialog-buttons {
  width: 700px !important;
  align-content: center;
  border: 1px solid #ccc;
  font-family: lato; }

#mediaBrowser.media-modal-frame.ui-dialog-content.ui-widget-content {
  margin-top: 6px; }

.ui-dialog-titlebar.ui-widget-header.ui-corner-all.ui-helper-clearfix {
  margin: -4px;
  color: #282828;
  background: #f0f0f0;
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 0;
  border: 1px solid #ccc; }


#edit-field-inline-video-source-und-form-field-vid-news-retouched-und-0-browse-button.button.browse.element-hidden {
  background: #282828;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase; }

#edit-field-inline-video-source-und-form-actions.form-wrapper.form-group {
  margin-top: 10px; }

#edit-actions.form-actions.form-wrapper.form-group {
  text-align: right; }

//close icon window browse video
button.ui-dialog-titlebar-close:before {
  font-family: FontAwesome;
  content: "\f00d";
  color: #505050;
  margin-bottom: 5px;
  font-weight: 100; }

.ui-dialog-titlebar-close {
  background: transparent;
  border: transparent; }

.ui-dialog .ui-dialog-titlebar-close {
  top: 15px !important; }

// remove button margin
#edit-field-inline-pictures-source-und-form-field-img-news-original-und-0-remove-buttonbtn.btn-danger.form-submit.ajax-processed {
  margin-top: 20px; }

.field-type-text.form-wrapper.form-group,
.field-type-media.form-wrapper.form-group {
  margin-bottom: 15px; }

.field-items .field-item:last-child .entity.entity-gin-pic-news-pic.gin-pic-news-pic-gin-pic-news-pic.clearfix {
  border: 0; }

// keywords banner in border
.keywords .row {
  margin: 0 -5px !important; }


#edit-field-inline-video-source-und-form-field-vid-news-original-und-0-edit,
#edit-field-inline-video-source-und-form-field-vid-news-retouched-und-0-edit {
  background: #282828;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1px;
  line-height: 36px;
  text-transform: uppercase;
  padding: 0 12px;
  display: inline-block; }

.help-block {
  font-size: 14px !important; }

.dn_upl_block fieldset.panel.panel-default .panel-body {
  background: #fdfdfd; }

//hide query banner with radio buttons
#field-query-add-more-wrapper {
  display: none; }

//Query commets style
.entity.entity-reply.reply-news.query-reply, .entity.entity-reply.reply-news.query-comment {
  border-top: 1px solid #ccc;
  margin: 10px;
  padding: 10px; }

.entity.entity-reply.reply-news.query-reply span:first-child {
  font-size: 16px; }

.entity.entity-reply.reply-news.query-reply {
  margin-left: 100px; }


//TABLE

.container-inline-date .form-item {
  width: 100%;
  input {}
  width: 100%; }

// source table
.control-label {
  text-align: left;
  text-transform: uppercase; }

.form-control, .input-group-addon {
  margin-bottom: 5px; }

.dn_upl_block legend.panel-heading {
  width: 100%; }

.ief-row-form .ief-form-row {
  border-bottom: 2px solid #f0f0f0; }

.dn_upl_block legend.panel-heading {
  width: 100% !important; }

.dn_upl_block legend.panel-heading .panel-title {
   font-size: 12px !important;
   margin-left: 30px; }

textarea {
  resize: none; }

.order-text-price-wrapper.order-media-price-wrap .col-sm-12.text-right {
	padding-right: 0; }

.row {
  margin-bottom: 15px !important; }

.table-responsive {
  margin-bottom: 15px; }

.breadcrumb {
  margin: -35px 0 0 0;
  background: none;
  text-align: right;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 0; }
